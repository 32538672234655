
import { AuthComponent } from '@/components/AuthComponent'
import { Routes, Route, Navigate } from 'react-router-dom'
import { HistoryRouter, history } from './utils/history'
import { lazy, Suspense, useEffect } from 'react'
import { Spin } from 'antd'

const AdminLogin = lazy(() => import('./pages/Admin/Login'))
const AdminHome = lazy(() => import('./pages/Admin/Home'))
const PartnerManage = lazy(() => import('./pages/Admin/PartnerManage'))
const HonorManage = lazy(() => import('./pages/Admin/HonorManage'))
const HonorH5Manage = lazy(() => import('./pages/Admin/HonorH5Manage'))
const StarHeadManage = lazy(() => import('./pages/Admin/StarHeadManage'))
const StarHeadH5Manage = lazy(() => import('./pages/Admin/StarHeadH5Manage'))
const StarManage = lazy(() => import('./pages/Admin/StarManage'))
const PostManage = lazy(() => import('./pages/Admin/PostManage'))
const StarEdit = lazy(() => import('./pages/Admin/StarEdit'))
const PostEdit = lazy(() => import('./pages/Admin/PostEdit'))
const PlanetManage = lazy(() => import('./pages/Admin/PlanetManage'))
const PlanetEdit = lazy(() => import('./pages/Admin/PlanetEdit'))
const DataManage = lazy(() => import('./pages/Admin/DataManage'))
const DataEdit = lazy(() => import('./pages/Admin/DataEdit'))

const PorterHome = lazy(() => import('./pages/Porter/Home'))
const PorterAbout = lazy(() => import('./pages/Porter/About'))
const PorterPaiyaData = lazy(() => import('./pages/Porter/CoreBusiness/PaiyaData'))
const PorterPaiyaPlanet = lazy(() => import('./pages/Porter/CoreBusiness/PaiyaPlanet'))
const PorterYuanpaiPlatform = lazy(() => import('./pages/Porter/YuanpaiPlatform'))
const CooperationCase = lazy(() => import('./pages/Porter/CooperationCase'))
const PorterContact = lazy(() => import('./pages/Porter/Contact'))
const PorterJoin = lazy(() => import('./pages/Porter/Join'))
const PorterIndex = lazy(() => import('./pages/Porter/Index/index'))

function App () {

  return (
    <>
      <HistoryRouter history={history}>
        <Suspense
          fallback={
            <div
              style={{
                textAlign: 'center',
                marginTop: 200
              }}
            >
              <Spin />
            </div>
          }
        >

          <Routes>
           
            <Route path='/' element={<PorterHome />} >
              <Route index element={<PorterIndex />}  />
              <Route path='about' element={<PorterAbout />} />
              <Route path='paiyamarketing' element={<PorterPaiyaData />} />
              <Route path='paiyamcn' element={<PorterPaiyaPlanet />} />
              <Route path='matapai' element={<PorterYuanpaiPlatform />} />
              <Route path='case' element={<CooperationCase />} />
              <Route path='contact' element={<PorterContact />} />
              <Route path='join' element={<PorterJoin />} />
            </Route>

            <Route path="admin" element={<AuthComponent><AdminHome /></AuthComponent>}>
              <Route path='partnerManage' element={<PartnerManage />}></Route>
              <Route path='honorManage' element={<HonorManage />}></Route>
              <Route path='honorH5Manage' element={<HonorH5Manage />}></Route>
              <Route path='starHeadManage' element={<StarHeadManage />}></Route>
              <Route path='starHeadH5Manage' element={<StarHeadH5Manage />}></Route>
              <Route path='starManage' element={<StarManage />}></Route>
              <Route path='postManage' element={<PostManage />}></Route>
              <Route path='starEdit/:id' element={<StarEdit />}></Route>
              <Route path='starAdd' element={<StarEdit />}></Route>
              <Route path='postAdd' element={<PostEdit />}></Route>
              <Route path='postEdit/:id' element={<PostEdit />}></Route>
              <Route path='planetManage' element={<PlanetManage />}></Route>
              <Route path='planetEdit/:id' element={<PlanetEdit />}></Route>
              <Route path='planetEdit' element={<PlanetEdit />}></Route>
              <Route path='dataManage' element={<DataManage />}></Route>
              <Route path='dataEdit/:id' element={<DataEdit />}></Route>
              <Route path='dataAdd' element={<DataEdit />}></Route>
            </Route>

            <Route path="/admin/login" element={<AdminLogin />} />

          </Routes>
        </Suspense>
      </HistoryRouter>
    </>
  )
}

export default App
