import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './style/index.scss'
import 'antd/dist/reset.css'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(

  <App />

)
