import axios from 'axios'
import { getToken } from './token'

const http = axios.create({
  baseURL: 'https://www.paiya-inc.com/paiya/back-end',
  //baseURL: 'http://127.0.0.1:8081/paiya/back-end',
  //baseURL: 'http://127.0.0.1:8082/paiya/',
  timeout: 500000
})
// 添加请求拦截器
http.interceptors.request.use((config) => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = `${token}`
  }
  return config
}, (error) => {
  //return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use((response) => {
  return response
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么

}, (error) => {
  //history.push('/admin/login')
  return Promise.reject(error)
})

export { http }